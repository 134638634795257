spacing = json('./styles/spacing.json', { hash: true })
colors = json('./styles/colors.json', { hash: true })
fonts = json('./styles/fonts.json', { hash: true })

:global
	html
	body
	div
	span
	applet
	object
	iframe
	h1
	h2
	h3
	h4
	h5
	h6
	p
	blockquote
	pre
	a
	abbr
	acronym
	address
	big
	cite
	code
	del
	dfn
	em
	img
	ins
	kbd
	q
	s
	samp
	small
	strike
	strong
	sub
	sup
	tt
	var
	b
	u
	i
	center
	dl
	dt
	dd
	ol
	ul
	li
	fieldset
	form
	label
	legend
	table
	caption
	tbody
	tfoot
	thead
	tr
	th
	td
	article
	aside
	canvas
	details
	embed
	figure
	figcaption
	footer
	header
	hgroup
	menu
	nav
	output
	ruby
	section
	summary
	time
	mark
	audio
	video
	input
		margin: 0
		padding: 0
		border: 0
		font-family: "Roboto", Helvetica, Arial, sans-serif

	// HTML5 display-role reset for older browsers
	article
	aside
	details
	figcaption
	figure
	footer
	header
	hgroup
	menu
	nav
	section
		display: block

	body
		line-height: 1

	h1
		font-size: fonts.giga
		font-weight: 500
		color: colors.black_grey

	h2
		font-size: fonts.beta
		font-weight: 500
		color: colors.black_grey

	h3
		font-size: fonts.zeta
		color: colors.slate

	:focus
		outline: none

